.blog-page{
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.blog-coverimage{
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.blog-title{
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  font-weight: 600;
  font-size: 30px;
}

.blog-content{
  margin: 40px 20px;
}

.blog-description{
  background-color: rgb(255, 255, 255);
  padding:30px 30px;
  margin-top: 30px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 50px; 
}

.category{
  margin-left: 0;
  display: inline-block;
  padding: 10px;
  border-radius: 15px;
  color: black;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.blogpage-navigation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px;
}