.contact{
    margin-top: 60px;
}

.contact-container {
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    max-width: 1024px;
    margin: auto;
    display: flex;
    align-items: center;
    border-radius: 15px;
}

.contact-title{
    color: #0E2431;
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    justify-content: center;
    font-size: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 600;
}

input[type=text],textarea {
    width: 100%;
    min-width: 200px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    font-size: 20px;
}
  
input[type=submit] {
    background-color: #38393a;
    color: white;
    padding: 12px 20px;
    border: none;
    font-size: 25px;
    border-radius: 4px;
    cursor: pointer;
    -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
  
input[type=submit]:hover {
    background-color: #4e4f50;
    transform: all 0.5s ease;
}
  
  

.contact-image{
    height: auto;
    width: auto;
    max-width: 500px;
}

textarea{
    height: 250px;
}

label{
    font-size: 25px;
}

@media screen and (max-width:768px) {
    .contact-container{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
    }
    .contact-image{
        width: 350px;
    }
}
