.blogs-title{
    color: #0E2431;
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    justify-content: center;
    font-size: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 600;
}

.blogs-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1024px;
    margin: auto;
}

#blogs{
    width: 100%;
    margin-top: 100px;
}