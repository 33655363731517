@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*Desktop View*/
.logo-nav {
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: space-between;
  max-width: 1024px;
  margin: 10px auto;
}

.header{
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: #fff;
    justify-content:space-between;
    box-shadow: 0 1px 4px rgba(146,161,176,.15);
    height: 60px;
}

.logo-nav a{
  text-decoration: none;
  color: #0E2431;
  font-size: 20px;
}

.nav-options {
  padding-left: 15px;
  display: flex;
  grid-gap: 25px;
  list-style-type: none;
}

.nav-options a{
  text-decoration: none;
  color: rgb(8, 8, 8);
  margin-right: 20px;
}

.nav-options a:hover{
  color: #e04f62;
  transition: all 0.5s ease;
}

.mobile-option {
  display: none;
}

.mobile-nav {
  display: none;
}

.right-navigations{
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme-toggle{
  cursor: pointer;
}

@media (max-width: 768px) {
  /*Mobile View */
  .header{
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    background-color: #fff;
    justify-content:space-between;
    box-shadow: 0 1px 4px rgba(146,161,176,.15);
    margin-top: 0;
  }

  .theme-toggle{
    display: none;
    cursor: pointer;
  }

  .left-logo{
    position: absolute;
    left: 10px;
  }
  
  .nav-options {
    display: flex;
    width: 50%;
    height: 100%;
    position: fixed;
    padding: 32px;
    top: 60px;
    right: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
    font-weight: 900;
    border-radius: 30px 0px;
  }

  .nav-options.active {
    background: #0E2431;
    right: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }

  .nav-options a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    color: #fff!important;
  }

  .mobile-nav{
    display: flex;
    align-items: center;
  }

  .mobile-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-icon{
    padding-left: 10px;
    margin-right: 15px;
    cursor: pointer;
  }
}

/* Mobile View */
@media screen and (max-width: 768px){
    .about-data{
        display: flex;
        flex-direction: column;
        align-items: center;
    }   
    .about-data h2,p{
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1.05rem;
        font-family:'Poppins', sans-serif ;
    }
    .about-data h2{
        font-size: 1.2rem;
    }
}

/* Desktop View */
.about-title{
    position: relative;
    color: #0E2431;
    margin-top: 10px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 2rem;
}

.about-data{
    display: flex;
    max-width: 1024px;
    margin: auto;
    align-items: center;
}

#about{
    margin-bottom: 100px;
}

@media screen and (min-width:768px) {
    .about-data h2,p{
        margin-left: 8rem;
        margin-right: 2rem;
        font-size: 1.05rem;
        font-family:'Poppins', sans-serif ;
        display: flex;
        text-align: left;
    }  
}


.about-data h2{
    font-size: 2rem;
}

.about-img img{
    width: 250px;
    height: 350px;
    border-radius: 15px;
    margin-left: 15px;
}

.tech-image{
    height: 40px;
    margin-right: 15px;
}



.body{
  max-width: 800px;
  margin: 10px;
}

.blog-card {
  display: flex;
  background-color: #fff;
  border-radius: 0.375rem;
  overflow: hidden;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.blog-card:hover .post-title {
    transition:color 0.3s ease;
    color:#e04f62;
  }

.post-image {
  transition:opacity 0.3s ease;
  display: block;
  width: 100%;
	object-fit: fill;
  max-width: 400px;
}

.article-details {
  padding: 1.5rem;
}

.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: 0 0 0.75rem 0;
  padding: 0 0 0.25rem 0;
  border-bottom: 0.125rem solid #ebebeb;
}

.post-title {
  transition:color 0.3s ease;
  font-size: 1.125rem;
  line-height: 1.4;
  color: #121212;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
}

.post-description {
  font-size: 0.875rem;
  line-height: 1;
  margin: 1.125rem 0 0 0;
  padding: 1.125rem 0 0 0;
  border-top: 0.0625rem solid #ebebeb;
}

.post-author {
  font-size: 0.875rem;
  line-height: 1;
  margin: 1.125rem 0 0 0;
  padding: 1.125rem 0 0 0;
  border-top: 0.0625rem solid #ebebeb;
}

@media (max-width:768px) {
  .blog-card {
    flex-wrap: wrap;
  }
}
  
#container {
  grid-area: main;
  align-self: center;
  justify-self: center;
}

.post-image {
  height: 100%;
}
  
.blog-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
}

@media screen and (max-width:768px) {
  .blog-card{
    display: flex;
  }

  .body{
    margin: 20px;
    width: 100%;
    max-width: 300px;
  }

  .post-image{
    display: flex;
    align-items: center;
  }
}
.blogs-title{
    color: #0E2431;
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    justify-content: center;
    font-size: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 600;
}

.blogs-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1024px;
    margin: auto;
}

#blogs{
    width: 100%;
    margin-top: 100px;
}
.contact{
    margin-top: 60px;
}

.contact-container {
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    max-width: 1024px;
    margin: auto;
    display: flex;
    align-items: center;
    border-radius: 15px;
}

.contact-title{
    color: #0E2431;
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    justify-content: center;
    font-size: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 600;
}

input[type=text],textarea {
    width: 100%;
    min-width: 200px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    font-size: 20px;
}
  
input[type=submit] {
    background-color: #38393a;
    color: white;
    padding: 12px 20px;
    border: none;
    font-size: 25px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
  
input[type=submit]:hover {
    background-color: #4e4f50;
    -webkit-transform: all 0.5s ease;
            transform: all 0.5s ease;
}
  
  

.contact-image{
    height: auto;
    width: auto;
    max-width: 500px;
}

textarea{
    height: 250px;
}

label{
    font-size: 25px;
}

@media screen and (max-width:768px) {
    .contact-container{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
    }
    .contact-image{
        width: 350px;
    }
}

.footer{
    background-color: #0E2431;
    color: #fff;
    text-align: center;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
  }
.footer-title,.footer-social{
  font-size: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.footer-copyright{
      margin: auto;
}

.footer-icon{
  font-size: 1.5rem;
  color: #fff;
  margin: 0 1rem;
}
.home{
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  height: calc(100vh - 3rem);
  grid-row-gap: 1rem;
  row-gap: 1rem;
  margin-bottom: 110px;
}

.home-title{
  font-size: 3.5rem;
  margin-bottom: 2.5rem;
  margin-left: 5px;
  margin-top: 50px;
}

.home-title span{
  color:  #e04f62;
}

.home-social-icon{
  width: -webkit-max-content;
  width: max-content;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #0E2431;
}

.home-social-icon:hover{
  color:  #e04f62;
  transition: all 0.5s ease;
}

.icon{
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
}

.home-img img{
  max-width: 370px;
  border-radius: 50%;
  object-fit: fill;
  margin-left: auto;
  margin-right: auto;
}

.gif{
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width:768px) {
  .home-social{
    display: flex;
    justify-content: space-around;
    bottom: 18%;
  }

  .home-data{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-img img{
    max-width: 280px;
  }
}

@media screen and (max-height:860px){
  .home-title{
    font-size: 2.8rem;
    margin-bottom: 2.5rem;
    margin-left: 5px;
    margin-top: 10px;
  }
  .home-img img{
    max-width: 200px;
  }
}


/* Desktop View */
@media screen and (min-width: 768px){
  .home{
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
  
  .home-data{
    align-self: flex-end;
    display: flex;
    align-items:center ;
    justify-content: space-between;
  }

  .home-social{
    padding-top: 0;
    padding-bottom: 2.5rem;
    flex-direction: row;
    align-self: flex-end;
  }

  .home-social-icon{
    margin-bottom: 0;
    margin-right: 80px;
  }

  .icon{
    -webkit-transform: scale(1.75);
            transform: scale(1.75);
  }
}
.projectcard-container {
  position: relative;
  margin: 30px;
  border-radius: 10px;
}

.image {
  display: block;
  width: 100%;
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
  height: auto;
  border-radius: 20px;
}

.card-info{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  border-radius: 10px;
  transition: all 0.5s ease;
  background-color: #212121;
}

.projectcard-container:hover .card-info {
  opacity: 1;
  transition: all 0.5s ease;
}

.text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.project-description{
  position: absolute;
  font-size: 25px;
  top: 50px;
  padding: 10px;
  color: #fff;
  text-align: center;
}

.buttons{
  position: absolute;
  left:auto;
  right:auto;
  bottom: 0;
}

button{
  margin:10px;
  height:42px;
  width: 100px;
  border-radius: 8px;
  outline: none;
  color: #0E2431;
  font-weight: 900;
  font-size: 15px;
  font-family:'Poppins', sans-serif ;
  cursor: pointer;
}
.projects p{
    color: #0E2431;
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    justify-content: center;
    font-size: 2rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
}

.projects-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1024px;
    margin: auto;
}

.projects{
    width: 100%;
}
.blog-page{
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.blog-coverimage{
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border-radius: 15px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.blog-title{
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  font-weight: 600;
  font-size: 30px;
}

.blog-content{
  margin: 40px 20px;
}

.blog-description{
  background-color: rgb(255, 255, 255);
  padding:30px 30px;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 50px; 
}

.category{
  margin-left: 0;
  display: inline-block;
  padding: 10px;
  border-radius: 15px;
  color: black;
  background-color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.blogpage-navigation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px;
}
