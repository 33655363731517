.home{
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  height: calc(100vh - 3rem);
  row-gap: 1rem;
  margin-bottom: 110px;
}

.home-title{
  font-size: 3.5rem;
  margin-bottom: 2.5rem;
  margin-left: 5px;
  margin-top: 50px;
}

.home-title span{
  color:  #e04f62;
}

.home-social-icon{
  width: max-content;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #0E2431;
}

.home-social-icon:hover{
  color:  #e04f62;
  transition: all 0.5s ease;
}

.icon{
  transform: scale(1.75);
}

.home-img img{
  max-width: 370px;
  border-radius: 50%;
  object-fit: fill;
  margin-left: auto;
  margin-right: auto;
}

.gif{
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width:768px) {
  .home-social{
    display: flex;
    justify-content: space-around;
    bottom: 18%;
  }

  .home-data{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-img img{
    max-width: 280px;
  }
}

@media screen and (max-height:860px){
  .home-title{
    font-size: 2.8rem;
    margin-bottom: 2.5rem;
    margin-left: 5px;
    margin-top: 10px;
  }
  .home-img img{
    max-width: 200px;
  }
}


/* Desktop View */
@media screen and (min-width: 768px){
  .home{
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
  
  .home-data{
    align-self: flex-end;
    display: flex;
    align-items:center ;
    justify-content: space-between;
  }

  .home-social{
    padding-top: 0;
    padding-bottom: 2.5rem;
    flex-direction: row;
    align-self: flex-end;
  }

  .home-social-icon{
    margin-bottom: 0;
    margin-right: 80px;
  }

  .icon{
    transform: scale(1.75);
  }
}