@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

.body{
  max-width: 800px;
  margin: 10px;
}

.blog-card {
  display: flex;
  background-color: #fff;
  border-radius: 0.375rem;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.blog-card:hover .post-title {
    transition:color 0.3s ease;
    color:#e04f62;
  }

.post-image {
  transition:opacity 0.3s ease;
  display: block;
  width: 100%;
	object-fit: fill;
  max-width: 400px;
}

.article-details {
  padding: 1.5rem;
}

.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: 0 0 0.75rem 0;
  padding: 0 0 0.25rem 0;
  border-bottom: 0.125rem solid #ebebeb;
}

.post-title {
  transition:color 0.3s ease;
  font-size: 1.125rem;
  line-height: 1.4;
  color: #121212;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
}

.post-description {
  font-size: 0.875rem;
  line-height: 1;
  margin: 1.125rem 0 0 0;
  padding: 1.125rem 0 0 0;
  border-top: 0.0625rem solid #ebebeb;
}

.post-author {
  font-size: 0.875rem;
  line-height: 1;
  margin: 1.125rem 0 0 0;
  padding: 1.125rem 0 0 0;
  border-top: 0.0625rem solid #ebebeb;
}

@media (max-width:768px) {
  .blog-card {
    flex-wrap: wrap;
  }
}
  
#container {
  grid-area: main;
  align-self: center;
  justify-self: center;
}

.post-image {
  height: 100%;
}
  
.blog-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
}

@media screen and (max-width:768px) {
  .blog-card{
    display: flex;
  }

  .body{
    margin: 20px;
    width: 100%;
    max-width: 300px;
  }

  .post-image{
    display: flex;
    align-items: center;
  }
}